<template>
  <div>
    <Header />
    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <div class="rowBlock">
            <h5 class="MediumTxt TituloCor1">TRATAMENTO<br />ANTIRREFLEXO</h5>

            <h1 class="TituloCor1" id="TituloVisaoSimples">Antiestática</h1>

            <a
              @click="Hide_Info()"
              href="javascript:void(0)"
              class="BtnFechaBeneficios top10"
              id="BtnFechaBeneficios"
            >
              <img
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>

          <div class="DivEscolhas">
            <div class="row top10">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1">Hidrofóbica</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
            <div class="owl-carousel owl-theme owl-beneficios">
              <div class="item">
                <div class="row">
                  <img
                    src="./images/Antirreflexo/Banner_Sujeira_1.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    A aplicação do Tratamento Antirreflexo. com sua camada
                    antiestática também garante menor aderência de poeira, muito
                    útil para pessoas que trabalham em ambientes com muito pó ou
                    nos momentos de lazer na praia.<br />
                    A aderência de poeira nas lentes, além de deixar a visão
                    turva e sem nitidez, podem também prejudicar a superfície
                    das lentes no momento da limpeza, pois mesmo usando o lenço
                    de microfibra, ao limpar a própria sujeira pode arranhar as
                    lentes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->

    <div class="AntirreflexoEscolha">
      <a
        @click="OpenLens('anti-glare/1')"
        class="BtnAntirreflexo"
        id="Chama_Reflexos"
      >
        <img
          src="./svg/Antirreflexo_Reflexos.svg"
          class="Icones"
          alt="Reflexos"
        />

        <h3>REFLEXOS</h3>
      </a>

      <a
        @click="OpenLens('anti-glare/2')"
        class="BtnAntirreflexo"
        id="Chama_Riscos"
      >
        <img src="./svg/Antirreflexo_Riscos.svg" class="Icones" alt="Riscos" />

        <h3>RISCOS</h3>
      </a>

      <a
        @click="OpenLens('anti-glare/3')"
        class="BtnAntirreflexo"
        id="Chama_Agua"
      >
        <img src="./svg/Antirreflexo_Agua.svg" class="Icones" alt="Agua" />

        <h3>ÁGUA</h3>
      </a>

      <a
        @click="OpenLens('anti-glare/4')"
        class="BtnAntirreflexo"
        id="Chama_Manchas"
      >
        <img
          src="./svg/Antirreflexo_Manchas.svg"
          class="Icones"
          alt="Manchas"
        />

        <h3>MANCHAS</h3>
      </a>

      <a
        @click="OpenLens('anti-glare/5')"
        class="BtnAntirreflexo Antirreflexo_BtnActive"
        id="Chama_Sujeira"
      >
        <img
          src="./svg/Antirreflexo_Sujeira.svg"
          class="Icones"
          alt="Sujeira"
        />

        <h3>SUJEIRA</h3>
      </a>
    </div>

    <Split style="height: 100vh; display: flex" @onDrag="onDrag">
      <SplitArea>
        <div class="split a" style="width: 100"></div>
      </SplitArea>
      <SplitArea>
        <div class="split b" style="width: 100"></div>
      </SplitArea>
    </Split>
  </div>
</template>

<script>
import VueSplit from 'vue-split-panel'
import Header from '@/views/menu/components/header.vue'
// import Buttons from '@/views/menu/components/anti_glare_buttons.vue'

import {
  InicialSet,
  DraggableButton,
} from '@/views/menu/js/new/DraggableButton_Scirpt'

import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

export default {
  methods: {
    DraggableButton,
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,

    OpenLens(route) {
      this.$router.push(`/lens/${route}`)
    },
  },
  components: {
    Header,
    // Buttons,
  },
  mounted() {
    InicialSet()
  },
}
</script>

<style scoped>
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import "./css/plugins/owl.carousel.min.css"; */
@import './css/plugins/owl.theme.default.min.css';

@import './css/AntirreflexoAntirriscos_Style.css';

@import './css/plugins/images-compare.css';

@import './css/plugins/Compare_Sujeira.css';

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.split {
  width: 101%;
  height: 100vh;
}
.a {
  background: url('./images/Antirreflexo/Antirreflexo_Sujeira_ComSujeira.jpg')
    center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.b {
  background: url('./images/Antirreflexo/Antirreflexo_Sujeira_SemSujeira.jpg')
    center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.gutter {
  background-color: red !important;
  width: 50px !important;
}
.gutter.gutter-horizontal {
  cursor: e-resize;

  left: 50% !important;
  margin-left: -25px !important;
  margin-right: -25px !important;
  margin-top: -25px !important;
  z-index: 5 !important;
  cursor: pointer !important;
  background: url('./svg/Setas.svg') center center no-repeat !important;
  background-size: 50px !important;
}
</style>
